export default {
  windowEl: window,
  documentEl: document,
  htmlEl: document.documentElement,
  bodyEl: document.body,
}


/*Faq */

document.querySelectorAll('.faq__item-button').forEach((el) => {
  el.addEventListener('click', () => {
    let faqInfo = el.closest('.faq__item').querySelector('.faq__item-info');
    faqInfo.classList.toggle('ac-active');
  });
});


document.addEventListener('scroll', function() {
  var ceoElement = document.getElementById('form_contact');  // Получаем элемент с ID 'ceo'
  var contactForm = document.querySelector('.form__contact');  // Получаем форму контактов
  var ceoPosition = ceoElement.getBoundingClientRect();  // Получаем позицию элемента 'ceo'

  // Проверяем, достигла ли прокрутка элемента 'ceo'
  if (ceoPosition.top <= window.innerHeight) {
      contactForm.style.opacity = '1';  // Делаем форму видимой
      contactForm.style.transform = 'translateY(0)';  // Перемещаем в исходное положение
  } else {
      contactForm.style.opacity = '0';  // Скрываем форму
      contactForm.style.transform = 'translateY(100%)';  // Смещаем обратно вниз
  }
});
