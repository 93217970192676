console.log('components');

// Определение операционной системы на мобильных
import { mobileCheck } from "./functions/mobile-check.js";
console.log(mobileCheck())

// Определение ширины экрана
import { isMobile, isTablet, isDesktop } from './functions/check-viewport';
if (isDesktop()) {
console.log('...')
}

// Реализация бургер-меню
import { burger } from './functions/burger';

// Реализация остановки скролла (не забудьте вызвать функцию)
import { disableScroll } from './functions/disable-scroll';



  
  
